import React from 'react'
import './App.css';
import './HelloWorld'
import './MyNavBar'
import HelloWorld from './HelloWorld';
import MyNavBar from './MyNavBar';



const App = () => {
  return (
    <div className = "App">

      <MyNavBar /> 
      <HelloWorld firstName = "Obul" lastName = "Kambham"/>
    </div>
    
  );
}

export default App;