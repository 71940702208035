/* eslint-disable no-unused-vars */
import React, { Component } from 'react' ;
import 'bootstrap/dist/css/bootstrap.css';
import './HelloWorld.css' ;
import { render } from '@testing-library/react';


class HelloWorld extends Component {

    constructor(props) {
        super(props);
        this.state = {greetings: 'Hello'};
        this.frenchise = this.frenchise.bind(this);
    }

    render () {
        
        return (

            <div className = "HelloWorld"> 
            <i class="fas fa-people-arrows"></i>
                {this.state.greetings} {this.props.firstName} {this.props.lastName} !
                <br/>
                <button onClick = {this.frenchise}>Frenchise!</button>

            </div>
        )
    }
    frenchise() {
        this.setState (
             {greetings: 'Bonjour'}
             );
    }
}

export default HelloWorld
